<!-- 产品筛选器 -->
<!-- 开发人员：王雪茹 -->
<template>
  <div>
    <el-card
      class="box-card"
      v-loading="loading"
      :style="{ height: tableHeight + 50 + 'px', top: topVal }"
    >
      <div slot="header" class="clearfixs">
        <!-- 产品筛选器 -->
        <span>
          {{ $t("label.product.filter") }}
        </span>
        <svg
          class="icon guanbi"
          aria-hidden="true"
          @click="handleClick('filterBoard')"
        >
          <use :href="'#icon-chahao'"></use>
        </svg>
      </div>
      <el-form ref="projectForm" :model="projectFormObj" v-if="choose">
        <!-- 产品代码 产品名称 产品系列-->
        <el-form-item :label="$t('label.product.code')">
          <el-input v-model="projectFormObj.cpdm"></el-input>
        </el-form-item>
        <el-form-item :label="$t('label.product.name')">
          <el-input v-model="projectFormObj.productname"></el-input>
        </el-form-item>
        <el-form-item :label="$t('label.product.series')">
          <el-select v-model="projectFormObj.cpxl" class="sel">
            <el-option
              v-for="item in lineArr"
              :key="item.codekey"
              :label="item.codekey"
              :value="item.codevalue"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-form ref="projectForm" :model="projectFormObj" v-if="config">
        <!-- 产品代码 产品名称 产品功能-->
        <el-form-item :label="$t('label.product.code')">
          <el-input v-model="projectFormObj.dm"></el-input>
        </el-form-item>
        <el-form-item :label="$t('label.product.name')">
          <el-input v-model="projectFormObj.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('label.product.function')" v-if="productGn">
          <el-input v-model="projectFormObj.region" class="sel"></el-input>
        </el-form-item>
      </el-form>
      <div class="btn_footer">
        <!-- 筛选 清除条件-->
        <el-button type="primary" size="small" @click="filterBtn">{{
          $t("component.timeline.button.filter")
        }}</el-button>
        <el-button
          type="text"
          size="small"
          style="color: #006dcc"
          @click="clearBtn"
          >{{ $t("label.clearing.conditions") }}</el-button
        >
      </div>
    </el-card>
  </div>
</template>
<script>
import * as CommonObjApi from "../api";

export default {
  props: {
    projectForm: {
      type: Object,
      default: () => {},
    },
    choose: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Boolean,
      default: false,
    },
    tableHeight: {
      type: [String, Number, undefined],
      default: 490,
    },
    productGn: {
      type: Boolean,
      default: false,
    },
    topVal: {
      type: String,
      default: "108px",
    },
  },
  data() {
    return {
      loading: false,
      lineArr: [],
      projectFormObj: this.projectForm,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      //获取产品系列
      CommonObjApi.getSelectValue({ fieldId: "ffe201100006402HlIN1" }).then(
        (res) => {
          this.lineArr = res.data;
        }
      );
    },
    //筛选器关闭
    handleClick() {
      this.$emit("handleClick");
    },
    //筛选
    filterBtn() {
      if (this.choose === true) {
        this.$emit("filterBtn");
      } else {
        this.$parent.showProductConfigList();
      }
    },
    //清除条件
    clearBtn() {
      this.$emit("clearBtn");
    },
  },
};
</script>
<style scoped lang="scss">
.box-card {
  position: absolute;
  right: 11px;
  z-index: 99;
  width: 247px;
  ::v-deep .el-card__header {
    border-bottom: none;
    .clearfixs {
      display: flex;
      justify-content: space-between;
      span {
        font-size: 14px;
        font-size: 12px;
        color: #080707;
      }
      .guanbi {
        width: 12px;
        height: 12px;
        margin-top: 3px;
        cursor: pointer;
      }
    }
  }
  ::v-deep .el-card__body {
    padding: 0 20px;
    .el-form {
      .el-form-item {
        margin-bottom: 10px;
        .el-form-item__label {
          font-size: 12px;
          width: 100%;
          text-align: left;
          line-height: 14px;
        }
        .el-form-item__content {
          margin-left: 0px;
          input {
            height: 30px;
            width: 171px;
          }
          .sel {
            input {
              width: 207px;
            }
          }
        }
      }
    }

    .btn_footer {
      margin-top: 70px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .el-button {
        width: 64px;
        height: 34px;
      }
    }
  }
}
</style>