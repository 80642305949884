<!-- CPQ-配置产品 -->
<!-- 开发人员：董浩南 -->
<template>
  <div class="objectTableMain">
    <div class="object_box" v-show="configQuo" v-loading="loading">
      <div class="price_box">
        <div class="tit">
          <svg class="viewImg" aria-hidden="true" @click="isFilterBtn">
            <use xlink:href="#icon-configLogo"></use>
          </svg>
          <div class="title">
            <p style="font-size: 12px">{{ quotenumber }}</p>
            <p style="font-size: 18px; color: #080707">
              {{ $t("label.partnerCloud.orderingPlatform.configuration") }}
            </p>
          </div>
        </div>
        <div class="search">
          <div class="btn_group">
            <div class="shaixuan">
              <svg class="shaixuan1" aria-hidden="true" @click="isFilterBtn">
                <use xlink:href="#icon-shaixuan1"></use>
              </svg>
            </div>
            <!-- <el-button size="small"> 应用规则 </el-button> -->
            <el-button-group>
              <el-button size="small" @click="cancelConfig">{{
                $t("label.emailsync.button.cancel")
              }}</el-button>
              <el-button
                style="background: #005fb2; color: #fff"
                size="small"
                type="primary"
                @click="configSaves"
                >{{ $t("label.tabpage.save") }}</el-button
              >
            </el-button-group>
          </div>
        </div>
      </div>
      <div class="wrap">
        <div class="navigationss">
          <navigation
            v-if="isNavbar"
            :navList="navList"
            :navName="navName"
            :isactive="isactivee"
            @jump="jump"
          />
        </div>
        <!-- 表格 -->
        <div class="configTable" v-loading="loadings">
          <div
            class="quotation-table"
            v-for="(product, index) in productList"
            :key="index"
          >
            <div class="goods">
              <!-- 价格手册条目产品名称 -->
              <div class="jgsc">
                <span>{{ productTitle }}</span>
              </div>
              <!-- tab栏 -->
              <div class="productTab" v-if="!isProduct">
                <div class="configTab">
                  <!-- tab栏名称 -->
                  <span
                    v-show="tabspan"
                    v-for="configTab in product.functionctategory"
                    :key="configTab.id"
                    @click="conTab(configTab)"
                    :class="{ active: isactive == configTab.id }"
                  >
                    {{ configTab.category }}
                  </span>
                </div>
                <div
                  class="twoTitle"
                  v-for="feature in featuree"
                  :key="feature.id"
                >
                  <!-- 产品分类名称 -->
                  <div class="goodstype">
                    <p>{{ feature.featurename }}</p>
                    <p>{{ feature.productXz }}</p>
                  </div>
                  <div class="Navtable">
                    <ul>
                      <li class="checkbox-li">
                        <el-checkbox
                          v-model="feature.checkAll"
                          v-if="feature.ischeckAll"
                          :key="feature.featurename"
                        ></el-checkbox>
                      </li>
                      <li
                        :style="{ width: attr.width }"
                        v-for="(attr, idx) in tableAttr"
                        :key="idx"
                      >
                        <el-tooltip
                          class="item"
                          :content="attr.label"
                          placement="top"
                        >
                          <span class="tootip-span">
                            {{ attr.label }}
                          </span>
                        </el-tooltip>
                      </li>
                    </ul>
                    <!-- 每一行的选择表格 -->
                    <quotation-line
                      v-for="(lineData, index) in feature.option"
                      :radio="radio"
                      :checkbox="lineData.check"
                      :line-data="lineData"
                      :tableAttr="tableAttr"
                      :ischeck="lineData.isCheck"
                      :checkAll="feature.checkAll"
                      :isDisabled="lineData.selectedd"
                      :selectedd="lineData.selectedd"
                      :key="index"
                      :editLineId="editLineId"
                      :editField="editField"
                      :ischecked="ischecked"
                      :editGroupIndex="editGroupIndex"
                      :editLineIndex="editLineIndex"
                      :configImgs="lineData.configImgs"
                      EditsSave="config"
                      isConfigCheck="true"
                      checkboxWidth="42px"
                      @childrenConfig="childrenConfig"
                      @modify="modify"
                      @checkLine="checkLine"
                      @radioLine="radioLine"
                      @edit="edit"
                    >
                      <el-radio
                        class="radios"
                        slot="firstColumn"
                        v-model="radio"
                        v-if="lineData.radioCheck"
                        :label="lineData.optionid"
                        >{{ kong }}</el-radio
                      >
                    </quotation-line>
                  </div>
                </div>
              </div>
            </div>
            <div class="goodstypee" v-if="isProduct">
              {{ $t("label.partnerCloud.orderingPlatform.noProduct") }}
            </div>
          </div>
        </div>
      </div>
      <!-- 筛选器 -->
      <product-screening
        v-if="isFilter"
        :config="config"
        :projectForm="projectForm"
        :productGn="productGn"
        @clearBtn="clearBtn"
        @handleClick="handleClick"
      >
      </product-screening>
    </div>
    <childrenConfig
      ref="childrenConfig"
      v-show="childQuo"
      :childQuo="childQuo"
      :pricebookid="pricebookid"
      :currency="currency"
      :id="id"
      :quoteId="quoteId"
      :groupIndex="groupIndex"
      :quotelineid="quotelineid"
      :configEcho="configEcho"
      :parentData="parentData"
      :pageTypes="pageTypes"
      :productId="cproductId"
      :cproductId="productId"
      @cancle="cancle"
    />
  </div>
</template>

<script>
import productScreening from "./components/productScreening.vue";
import navigation from "./components/navigation.vue";
import QuotationLine from "./components/Line.vue";
import childrenConfig from "./childrenConfig.vue";
import * as quotationApi from "./api.js";

export default {
  name: "configQuotation",
  components: { navigation, productScreening, childrenConfig, QuotationLine },
  props: {
    parentData: {
      type: Array,
      default: null,
    },
    pageTypes: {
      type: String,
      default: "",
    },
    pricebookid: {
      //价格手册id
      type: String,
      default: "",
    },
    currency: {
      //币种
      type: String,
      default: "",
    },
    id: {
      // 报价单id
      type: String,
      default: "",
    },
    groupIndex: {
      //报价行组下标
      type: String,
      default: "",
    },
    quoteId: {
      type: String,
      default: "",
    },
    quotelineid: {
      type: String,
      default: "",
    },
    configEcho: {
      //需要回显的报价行数据
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedArr: [],
      cproductId: "",
      childQuo: false,
      configQuo: true,
      // pageType: "",
      // goodstypeHight: "800px",
      config: true,
      // check: true, //复选框
      kong: "", //单选框label隐藏
      tableAttr: [],
      productList: null,
      // productListChildren: null,
      isFilter: false,
      // quotatioTable: "",
      navList: [],
      isNavbar: false,
      radio: "1",
      // cpradio: "",
      projectForm: {
        dm: "",
        name: "",
        region: "",
      },
      // checkAll: false,
      // showIndex: true, //序号
      // radioCheck: false, //单选框
      // requireChecked: "", //编辑框
      featuree: null,
      isactive: "",
      isactivee: "",
      // navId: "", //导航栏当前点击id
      editLineId: "",
      editField: "",
      controlArr: [],
      quantitys: "",
      editId: "",
      // childProduct: "",
      groupId: "",
      optionListArr: [],
      productTitle: "",
      navName: this.$i18n.t("label.partnerCloud.orderingPlatform.bundle"),
      lengthId: "",
      isProduct: false, //是否有产品选项
      ischecked: false, //是否勾选
      quotionData: [],
      // productoption: "", //产品选项
      parentId: "", //父产品id
      parentListprice: "", //父产品价格
      parentQuantity: "", //父产品数量
      parentProduct: "", //产品选项
      currentuotelineid: this.$route.query.quotelineid, //报价行id
      constraintList: [], //约束数组
      tabspan: false,
      productGn: true, //产品功能搜索框
      loading: false,
      // isConfigSum: 0,
      // isConfigs: false,
      configChildren: [],
      configChildrenTwo: [],
      configChildrenThree: [],
      configChildrenfour: [],
      quotenumber: "",
      loadings: false,
      jiaoyanArr: [],
      jiaoyanArrr: [],
      newOptionArr: [],
      rules: false,
      // twopProducId: "",
      // threepProducId: "",
      // fourProducId: "",
      editGroupIndex: "", // 正在编辑数据所在组下标
      editLineIndex: "", // 正在编辑数据下标
      ylArr: [],
      radioArr: [],
      // rely: "",
      Srely: [],
      isRelyOn: false,
      currentParentData: this.parentData,
    };
  },
  watch: {
    optionListArr() {
      this.optionListArr.forEach((item) => {
        this.newOptionArr.push(item);
      });
      this.newOptionArr = this.unique(this.newOptionArr, "product");
      //取消勾选不保存该数据
      for (var i = 0; i < this.newOptionArr.length; i++) {
        this.selectedArr.forEach((item, index) => {
          if (item.id === this.newOptionArr[i]?.productoption) {
            this.selectedArr.splice(index, 1);
          }
        });

        //二级捆绑
        this.ylArr.forEach((item) => {
          if (item.id == this.newOptionArr[i]?.productoption) {
            this.newOptionArr.splice(i, 1);
          }
        });
      }
    },
  },
  created() {
    this.init();
    this.optionConstraint();
    this.parentList();
  },
  mounted() {},
  methods: {
    init() {
      this.groupId = this.groupIndex;
      this.isNav();
    },
    // clear() {
    //   this.optionListArr = [];
    //   this.configChildren = [];
    //   this.configChildrenTwo = [];
    //   this.configChildrenThree = [];
    // },
    //去重
    unique(arr, key) {
      // 根据唯一标识orderId来对数组进行过滤
      const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
      return arr.filter((arr) => !res.has(arr[key]) && res.set(arr[key], 1));
    },
    //多级捆绑配置
    childrenConfig(id) {
      this.verify();
      if (!this.rules) {
        return false;
      } else {
        this.childQuo = true;
        this.configQuo = false;
        this.cproductId = id;
        this.$nextTick(() => {
          this.$parent.$refs.configQuotation.$refs.childrenConfig.isNav();
        });
      }
    },
    //约束关系数组
    optionConstraint() {
      quotationApi
        .optionConstraint({ productid: this.productId })
        .then((res) => {
          this.constraintList = res.data;
        });
    },
    //接收父产品参数
    parentList() {
      if (this.pageTypes === "quote") {
        this.currentParentData = this.$parent.parentData;
      }
      if (this.pageTypes === "chooseProduct") {
        this.currentParentData = this.$parent.$parent.parentData;
      }
      if (
        this.currentParentData !== "" ||
        this.currentParentData !== undefined ||
        this.currentParentData !== null
      ) {
        this.currentParentData.forEach((item) => {
          if (this.productId === item.product) {
            this.parentId = item.id;
            this.parentListprice = item.listprice;
            this.parentQuantity = item.quantity;
            this.parentProduct = item.product;
          }
        });
      }
    },
    checkLine(checked, list) {
      /**
        "constraintList 约束数组(接口返回的)": this.constraintList,
        "list 当前点击时选择的一行的对象":list,
        "controlArr 所有可以选择的没有区分类型的 选项列表":this.controlArr
       */
      if (checked) {
        if (
          this.constraintList !== "" ||
          this.constraintList !== null ||
          this.constraintList !== undefined
        ) {
          this.selectedArr.push(list);
          this.constraintList.forEach((constType) => {
            //互斥关系（双向互斥关系）如果用户选择了约束产品选项，则受约束产品选项默认不可选，复选框为灰色
            if (constType.type === "互斥") {
              if (constType.constrainedoption === list.optionid) {
                /**
                 constrainingoption 限制选项id
                  */
                this.controlArr.forEach((item) => {
                  if (constType.constrainingoption === item.optionid) {
                    this.$delete(item, "selectedd");
                    this.$set(item, "selectedd", true);
                  }
                });
              }
              if (constType.constrainingoption === list.optionid) {
                this.controlArr.forEach((item) => {
                  if (constType.constrainedoption === item.optionid) {
                    this.$delete(item, "selectedd");
                    this.$set(item, "selectedd", true);
                  }
                });
              }
            }
            //依赖关系（单向关系）：购买约束产品选项，受约束产品选项才可以勾选。若未购买约束产品选项，则受约束产品选项复选框为灰色，不可勾选。
            if (constType.type === "依赖") {
              if (constType.constrainingoption === list.optionid) {
                list.constraintClick = true;
                this.controlArr.forEach((item) => {
                  if (constType.constrainedoption === item.optionid) {
                    if (list.constraintClick) {
                      item.selectedd = false;
                      this.isRelyOn = true;
                    }
                  }
                });
              }
            }
          });
        }
        this.ischecked = true;
        list.isCheck = true;
        this.$delete(list, "isCheck");
        this.$set(list, "isCheck", true);
        let idArr = [];
        let listidArr = [];
        listidArr.push(list.id);
        this.optionListArr.forEach((newlist) => {
          idArr.push(newlist.productoption);
        });
        //勾选状态保存数组如果没有该数据添加该数据至保存数组
        for (var i = 0; i < listidArr.length; i++) {
          if (idArr.indexOf(listidArr[i]) == -1 && list.radioCheck === false) {
            this.optionListArr.push({
              ids: list.id,
              id: list.productid,
              featurename: list.featurename,
              product: list.optionalsku,
              productoption: list.optionid,
              quantitysetup: list.quantity,
            });
          }
        }
      } else {
        if (
          this.constraintList !== "" ||
          this.constraintList !== null ||
          this.constraintList !== undefined
        ) {
          // 这里先强制清除一下
          this.selectedArr.forEach((item, index) => {
            if (item.id === list.id) {
              this.selectedArr.splice(index, 1);
            }
          });

          if (this.selectedArr.length >= 1) {
            this.constraintList.forEach((constType) => {
              //互斥关系（双向互斥关系）如果用户选择了约束产品选项，则受约束产品选项默认不可选，复选框为灰色
              if (constType.type === "互斥") {
                if (constType.constrainedoption === list.optionid) {
                  //互斥
                  this.controlArr.forEach((item) => {
                    this.Srely.forEach((Srely) => {
                      if (
                        constType.constrainingoption === item.optionid &&
                        constType.constrainingoption !== Srely.id
                      ) {
                        this.$delete(item, "selectedd");
                        this.$set(item, "selectedd", false);
                      }
                    });
                  });
                }
                if (constType.constrainingoption === list.optionid) {
                  //受互斥
                  this.controlArr.forEach((item) => {
                    this.Srely.forEach((Srely) => {
                      if (
                        constType.constrainedoption === item.optionid &&
                        constType.constrainedoption == Srely.id
                      ) {
                        this.$delete(item, "selectedd");
                        this.$set(item, "selectedd", false);
                      }
                    });
                  });
                }
              }
              if (constType.type === "依赖") {
                if (constType.constrainingoption === list.optionid) {
                  this.controlArr.forEach((item) => {
                    //依赖产品取消后收依赖产品状态改变
                    if (constType.constrainedoption === item.optionid) {
                      if (list.constraintClick) {
                        this.ylArr.push(item);
                        this.isRelyOn = false;
                        // this.$delete(item, "selectedd");
                        // this.$delete(item, "isCheck");
                        this.$set(item, "selectedd", true);
                        this.$set(item, "isCheck", false);
                      }
                    }
                  });
                }
                this.constraintList.forEach((constTypes) => {
                  if (constTypes.type === "互斥") {
                    if (
                      constTypes.constrainedoption ===
                      constType.constrainedoption
                    ) {
                      //互斥
                      this.controlArr.forEach((item) => {
                        if (constTypes.constrainingoption === item.optionid) {
                          this.$delete(item, "selectedd");
                          this.$set(item, "selectedd", false);
                        }
                      });
                    }
                    if (
                      constTypes.constrainingoption ===
                      constType.constrainingoption
                    ) {
                      //受互斥
                      this.controlArr.forEach((item) => {
                        if (constTypes.constrainedoption === item.optionid) {
                          this.$delete(item, "selectedd");
                          this.$set(item, "selectedd", false);
                        }
                      });
                    }
                  }
                });
              }
            });
          } else {
            this.controlArr.forEach((item) => {
              this.$set(item, "selectedd", false);
            });
          }
        }
        list.isCheck = false;
        this.ischecked = false;
        //取消勾选不保存该数据 productoption
        for (let i = 0; i < this.optionListArr.length; i++) {
          //二级捆绑
          //清除必须配置的子产品
          if (list.id == this.optionListArr[i]?.productoption) {
            this.optionListArr.splice(i, 1);
          }
        }
        //取消勾选不保存该数据
        for (let i = 0; i < this.newOptionArr.length; i++) {
          //二级捆绑
          if (list.id == this.newOptionArr[i]?.productoption) {
            this.newOptionArr.splice(i, 1);
          }
        }
      }
      // })
    },
    radioLine(id, list) {
      this.radioArr = [];
      if (this.radio === id) {
        this.radioArr.push({
          ids: list.id,
          id: list.productid,
          featurename: list.featurename,
          product: list.optionalsku,
          productoption: list.optionid,
          quantitysetup: list.quantity,
        });
      }
      this.radioArr.forEach((item) => {
        this.newOptionArr.push({
          ids: item.ids,
          id: item.id,
          featurename: item.featurename,
          product: item.product,
          productoption: item.productoption,
          quantitysetup: item.quantity,
        });
      });
      for (var i = 0; i < this.newOptionArr.length; i++) {
        if (this.radio !== this.newOptionArr[i].ids) {
          this.newOptionArr.splice(i, 1);
        }
      }
    },
    //清除条件
    clearBtn() {
      this.projectForm = {
        dm: "",
        name: "",
        region: "",
      };
      this.showProductConfigList();
      this.isFilter = false;
    },
    verify() {
      //转成校验需要的数据
      let a = [];
      let jiaoyanLength = [];
      this.jiaoyanArr.forEach((feature) => {
        jiaoyanLength.push({
          featurename: feature.featureId,
          jiaoyanarr: [],
        }); //勾选的数据放入新数组
        this.newOptionArr.forEach((item) => {
          jiaoyanLength.forEach((ite) => {
            if (item.featurename === ite.featurename) {
              ite.jiaoyanarr.push(item);
              a = ite.jiaoyanarr;
              a = this.unique(a, "productoption");
              ite.jiaoyanarr = a;
            }
          });
        });
      });
      this.jiaoyanArr.forEach((jiaoyanArrs) => {
        jiaoyanLength.forEach((jiaoyanLengths) => {
          if (jiaoyanArrs.featurename === jiaoyanLengths.featurename) {
            jiaoyanArrs.childrenLength = jiaoyanLengths.jiaoyanarr.length; //勾选数据的数量
            jiaoyanArrs.minoptioncount = Number(jiaoyanArrs.minoptioncount); //最小数量
            jiaoyanArrs.maxoptioncount = Number(jiaoyanArrs.maxoptioncount); //最大数量
          }
        });
      });
      this.jiaoyanArrr = jiaoyanLength;
      this.rules = true;
      for (let i = 0; i < this.jiaoyanArr.length; i++) {
        if (
          this.jiaoyanArr[i].childrenLength >
            this.jiaoyanArr[i].maxoptioncount &&
          this.jiaoyanArr[i].minoptioncount == 0 &&
          this.jiaoyanArr[i].maxoptioncount !== 0
        ) {
          this.$message({
            message: this.$i18n.t("label.product.proofOne", [
              this.jiaoyanArr[i].featurename,
              this.jiaoyanArr[i].maxoptioncount,
            ]),
            type: "warning",
          });
          this.rules = false;
          break;
        }
        if (
          this.jiaoyanArr[i].childrenLength <
            this.jiaoyanArr[i].minoptioncount &&
          this.jiaoyanArr[i].maxoptioncount == 0 &&
          this.jiaoyanArr[i].minoptioncount !== 0
        ) {
          this.$message({
            message: this.$i18n.t("label.product.proofTwo", [
              this.jiaoyanArr[i].featurename,
              this.jiaoyanArr[i].minoptioncount,
            ]),
            type: "warning",
          });
          this.rules = false;
          break;
        }
        if (
          this.jiaoyanArr[i].minoptioncount !== 0 &&
          this.jiaoyanArr[i].maxoptioncount !== 0
        ) {
          if (
            this.jiaoyanArr[i].childrenLength <
              this.jiaoyanArr[i].minoptioncount ||
            this.jiaoyanArr[i].childrenLength >
              this.jiaoyanArr[i].maxoptioncount
          ) {
            this.$message({
              message: this.$i18n.t("label.product.proof.Three", [
                this.jiaoyanArr[i].featurename,
                this.jiaoyanArr[i].minoptioncount,
                this.jiaoyanArr[i].maxoptioncount,
              ]),
              type: "warning",
            });
            this.rules = false;
            break;
          }
        }
      }
    },
    // recalculate() {
    //   this.$parent.recalculate();
    // },
    configRule() {
      //规则走完后保存
      if (this.pageTypes === "quote") {
        this.newOptionArr.forEach((item) => {
          item.childproduct = [];
          //初始化多级捆绑
          this.configChildren.forEach((ChildrenTwo) => {
            if (
              ChildrenTwo.parentd === item.product ||
              ChildrenTwo.id === item.product
            ) {
              item.childproduct.push({
                featurename: ChildrenTwo.featurename,
                id: ChildrenTwo.id,
                product: ChildrenTwo.product,
                productoption: ChildrenTwo.productoption,
                // quantitysetup: ChildrenTwo.quantity,
                quantitysetup: ChildrenTwo.quantitysetup,
              });
            }
          });
          if (item.childproduct) {
            item.childproduct.forEach((ite) => {
              ite.childproduct = [];
              this.configChildrenTwo.forEach((configChildrenTw) => {
                if (
                  configChildrenTw.parentd === ite.product ||
                  configChildrenTw.id === ite.product
                ) {
                  ite.childproduct.push({
                    featurename: configChildrenTw.featurename,
                    id: configChildrenTw.id,
                    product: configChildrenTw.product,
                    productoption: configChildrenTw.productoption,
                    // quantitysetup: configChildrenTw.quantity,
                    quantitysetup: configChildrenTw.quantitysetup,
                  });
                }
              });
              if (ite.childproduct) {
                ite.childproduct.forEach((it) => {
                  it.childproduct = [];
                  this.configChildrenThree.forEach((ChildrenThree) => {
                    if (
                      ChildrenThree.parentd === it.product ||
                      ChildrenThree.id === it.product
                    ) {
                      it.childproduct = this.configChildrenThree;
                    }
                  });
                });
              }
            });
          }
        });
      } else {
        this.newOptionArr.forEach((item) => {
          item.childproduct = [];
          //初始化多级捆绑
          this.configChildren.forEach((ChildrenTwo) => {
            if (
              ChildrenTwo.parentd === item.id &&
              ChildrenTwo.id === item.product
            ) {
              item.childproduct.push({
                featurename: ChildrenTwo.featurename,
                id: ChildrenTwo.id,
                product: ChildrenTwo.product,
                productoption: ChildrenTwo.productoption,
                // quantitysetup: ChildrenTwo.quantity,
                quantitysetup: ChildrenTwo.quantitysetup,
              });
            }
          });
          if (item.childproduct) {
            item.childproduct.forEach((ite) => {
              ite.childproduct = [];
              this.configChildrenTwo.forEach((configChildrenTw) => {
                if (
                  configChildrenTw.parentd === item.id &&
                  configChildrenTw.id === ite.product
                ) {
                  ite.childproduct.push({
                    featurename: configChildrenTw.featurename,
                    id: configChildrenTw.id,
                    product: configChildrenTw.product,
                    productoption: configChildrenTw.productoption,
                    // quantitysetup: configChildrenTw.quantity,
                    quantitysetup: configChildrenTw.quantitysetup,
                  });
                }
              });
              if (ite.childproduct) {
                ite.childproduct.forEach((it) => {
                  this.configChildrenThree.forEach((ChildrenThree) => {
                    if (
                      ChildrenThree.parentd === item.id &&
                      ChildrenThree.id === it.product
                    ) {
                      it.childproduct = this.configChildrenThree;
                    }
                  });
                });
              }
            });
          }
        });
      }
    },
    configSaves() {
      this.verify();
      let cloneIte = null;
      this.quotionData = [];
      if (!this.rules) {
        return false;
      } else {
        this.configRule();
        if (this.pageTypes === "quote") {
          //报价单跳转来的
          if (
            this.groupIndex === "" ||
            this.groupIndex === undefined ||
            this.groupIndex === null
          ) {
            //不是组的情况
            //单个产品进行添加
            this.currentParentData.forEach((item) => {
              item.index = this.$parent.listIndex;
              item.childproduct = JSON.parse(
                //数据深拷贝防止被克隆的元素改变
                JSON.stringify(this.newOptionArr)
              );
              var newArr = item.childproduct.filter((ite) => {
                //去掉不是当前产品下的子产品
                return item.product === ite.id;
              });
              item.childproduct = newArr;
              item.childproduct.forEach((it) => {
                //去掉之前用以判断添加的元素
                this.$delete(it, "id");
              });
            });
            for (let j = 0; j < this.$parent.quoteLineList.length; j++) {
              this.quotionData.push(this.$parent.quoteLineList[j]);
            }
            for (let i = 0; i < this.parentData.length; i++) {
              //循环放到数组里
              this.quotionData.push(this.parentData[i]);
            }
          } else {
            //有报价行组情况
            //单个产品进行添加
            this.parentData.forEach((item) => {
              item.childproduct = JSON.parse(
                //数据深拷贝防止被克隆的元素改变
                JSON.stringify(this.newOptionArr)
              );
              var newArr = [];
              newArr = item.childproduct.filter((ite) => {
                //去掉不是当前产品下的子产品
                return item.product === ite.id;
              });
              item.childproduct = newArr;
              item.childproduct.forEach((it) => {
                //去掉之前用以判断添加的元素
                this.$delete(it, "id");
              });
            });
            for (
              let j = 0;
              j < this.$parent.lineGroup[this.groupIndex].quoteLineJson.length;
              j++
            ) {
              this.quotionData.push(
                this.$parent.lineGroup[this.groupIndex].quoteLineJson[j]
              );
            }
            for (let i = 0; i < this.parentData.length; i++) {
              //循环放到数组里
              this.quotionData.push(this.parentData[i]);
            }
          }
          this.$emit(
            "configSave",
            this.quotionData,
            this.groupIndex,
            this.newOptionArr,
            this.$parent.listIndex
          );
        } //选择产品跳转来的
        if (this.pageTypes === "chooseProduct") {
          if (
            //没有报价行组
            this.groupIndex === "" ||
            this.groupIndex === undefined ||
            this.groupIndex === null
          ) {
            if (this.parentData.length > 1) {
              //多个产品进行添加
              this.parentData.forEach((item) => {
                item.childproduct = JSON.parse(
                  //数据深拷贝防止被克隆的元素改变
                  JSON.stringify(this.newOptionArr)
                );
                var newArr = item.childproduct.filter((ite) => {
                  //去掉不是当前产品下的子产品
                  return item.product === ite.id;
                });
                item.childproduct = newArr;
                item.childproduct.forEach((it) => {
                  //去掉之前用以判断添加的元素
                  this.$delete(it, "id");
                });
              });
              for (let j = 0; j < this.$parent.quoteLineList.length; j++) {
                this.quotionData.push(this.$parent.quoteLineList[j]);
              }
              for (let i = 0; i < this.parentData.length; i++) {
                //循环放到数组里
                this.quotionData.push(this.parentData[i]);
              }
            } else {
              //单个产品进行添加
              this.parentData.forEach((item) => {
                item.childproduct = JSON.parse(
                  //数据深拷贝防止被克隆的元素改变
                  JSON.stringify(this.newOptionArr)
                );
                var newArr = item.childproduct.filter((ite) => {
                  //去掉不是当前产品下的子产品
                  return item.product === ite.id;
                });
                item.childproduct = newArr;
                item.childproduct.forEach((it) => {
                  //去掉之前用以判断添加的元素
                  this.$delete(it, "id");
                });
              });
              for (let j = 0; j < this.$parent.quoteLineList.length; j++) {
                this.quotionData.push(this.$parent.quoteLineList[j]);
              }
              for (let i = 0; i < this.parentData.length; i++) {
                //循环放到数组里
                this.quotionData.push(this.parentData[i]);
              }
            }
            // this.recalculate()
            //   .then(() => {
            //     //调用重新计算接口并跳转到报价单页面
            //     this.$parent.pageType = "chooseProduct";
            //     this.pageTypes = "quote";
            //     this.cancelConfig();
            //   })
            //   .catch(() => {
            //   });
          } else {
            //有报价行组
            if (this.parentData.length > 1) {
              //多个产品进行添加
              this.parentData.forEach((item) => {
                item.childproduct = JSON.parse(
                  //数据深拷贝防止被克隆的元素改变
                  JSON.stringify(this.newOptionArr)
                );
                var newArr = item.childproduct.filter((ite) => {
                  //去掉不是当前产品下的子产品
                  return item.product === ite.id;
                });
                item.childproduct = newArr;
                item.childproduct.forEach((it) => {
                  //去掉之前用以判断添加的元素
                  this.$delete(it, "id");
                });
              });
              for (
                let j = 0;
                j <
                this.$parent.lineGroup[this.groupIndex].quoteLineJson.length;
                j++
              ) {
                this.quotionData.push(
                  this.$parent.lineGroup[this.groupIndex].quoteLineJson[j]
                );
              }
              for (let i = 0; i < this.parentData.length; i++) {
                //循环放到数组里
                this.quotionData.push(this.parentData[i]);
              }
            } else {
              //单个产品进行添加
              this.parentData.forEach((item) => {
                item.childproduct = JSON.parse(
                  //数据深拷贝防止被克隆的元素改变
                  JSON.stringify(this.newOptionArr)
                );
                var newArr = [];
                newArr = item.childproduct.filter((ite) => {
                  //去掉不是当前产品下的子产品
                  return item.product === ite.id;
                });
                item.childproduct = newArr;
                item.childproduct.forEach((it) => {
                  //去掉之前用以判断添加的元素
                  this.$delete(it, "id");
                });
              });
              for (
                let j = 0;
                j <
                this.$parent.lineGroup[this.groupIndex].quoteLineJson.length;
                j++
              ) {
                this.quotionData.push(
                  this.$parent.lineGroup[this.groupIndex].quoteLineJson[j]
                );
              }
              for (let i = 0; i < this.parentData.length; i++) {
                //循环放到数组里
                this.quotionData.push(this.parentData[i]);
              }
            }
            // this.recalculate()
            //   .then(() => {
            //     this.$parent.pageType = "chooseProduct";
            //     this.pageTypes = "quote";
            //     this.cancelConfig();
            //   })
            //   .catch(() => {
            //   });
          }
          this.quotionData.forEach((item) => {
            if (item.isconfig === false) {
              let idss = [];
              idss.push(item.product);
              idss = idss.toString();
              quotationApi.getRequiredOptions({ ids: idss }).then((res) => {
                res.data.forEach((ite) => {
                  if (item.product === ite.product) {
                    this.$delete(item, "childproduct");
                    cloneIte = ite.childproduct;
                    item.childproduct = JSON.parse(JSON.stringify(cloneIte));
                  }
                });
              });
            }
          });
          this.$emit("configSave", this.quotionData, this.groupIndex);
        }
      }
    },
    // 报价行表格内联编辑
    edit(groupIndex, index, prop, id) {
      this.editGroupIndex = groupIndex;
      this.editLineIndex = index;
      this.editField = prop;
      this.editId = id;
      this.editLineId = id;
    },
    // 修改列表值
    modify(groupIndex, lineIndex, prop, inputNumber) {
      // 无分组
      this.modifyQuoteLine(this.controlArr, lineIndex, prop, inputNumber);
    },
    // 递归查找数据进行修改
    modifyQuoteLine(controlArr, lineIndex, prop, inputNumber) {
      controlArr.forEach((productLine) => {
        if (productLine.index === lineIndex && productLine.id === this.editId) {
          productLine[prop] = this.cutOutNum(inputNumber);
          this.quantitys = productLine[prop];
          if (prop !== "discount" && prop !== "additionaldiscount") {
            this.$set(productLine, `${prop}edit`, "true");
          } else {
            this.$set(productLine, `additionaldiscountedit`, "true");
          }
          return;
        }
      });
      //改变原来配置项的值
      this.newOptionArr.forEach((newList) => {
        if (newList.productoption === this.editId) {
          newList.quantitysetup = this.quantitys.toString();
        }
      });
    },
    //tab切换
    conTab(item) {
      this.featuree = item.feature;
      this.isactive = item.id;
    },

    //保留两位小数
    cutOutNum(num, decimals) {
      if (isNaN(num) || (!num && num !== 0)) {
        return "--";
      }
      // 默认为保留的小数点后两位
      let dec = decimals ? decimals : 2;
      let tempNum = Number(num);
      let pointIndex = String(tempNum).indexOf(".") + 1; // 获取小数点的位置 + 1
      let pointCount = pointIndex ? String(tempNum).length - pointIndex : 0; // 获取小数点后的个数(需要保证有小数位)
      // 源数据为整数或者小数点后面小于decimals位的作补零处理
      if (pointIndex === 0 || pointCount <= dec) {
        let tempNumA = tempNum;
        if (pointIndex === 0) {
          tempNumA = `${tempNumA}.`;
          for (let index = 0; index < dec - pointCount; index++) {
            tempNumA = `${tempNumA}0`;
          }
        } else {
          for (let index = 0; index < dec - pointCount; index++) {
            tempNumA = `${tempNumA}0`;
          }
        }
        return tempNumA;
      }
      let realVal = "";
      // 截取当前数据到小数点后decimals位
      realVal = `${String(tempNum).split(".")[0]}.${String(tempNum)
        .split(".")[1]
        .substring(0, dec)}`;
      // 判断截取之后数据的数值是否为0
      if (realVal == 0) {
        realVal = 0;
      }
      return realVal;
    },
    //是否展示导航栏
    isNav() {
      this.productId = JSON.parse(localStorage.getItem("productId"));
      let prrId = this.productId.split(",");
      //产品id>1时展示导航栏
      if (prrId.length > 1) {
        let params = {
          productid: this.productId,
          currency: this.currency,
          quoteid: this.id,
          pricebookid: this.pricebookid,
        };
        quotationApi.showPricebookItems(params).then((res) => {
          //为空的话不展示侧边栏
          if (res.data == "" || res.data == undefined || res.data == null) {
            this.isNavbar = false;
          } else {
            this.isNavbar = true;
            res.data.forEach((item) => {
              this.navList.push({
                id: item.productid,
                title: item.productname,
                isEdit: false,
              });
            });
            if (res.data == "" || res.data == null || res.data == undefined) {
              //默认展示第一个产品
              this.productTitle = res.data[0].productname;
              this.productId = res.data[0].productid;
              this.lengthId = prrId.length;
              this.isProduct = true;
            } else {
              this.productTitle = res.data[0].productname;
              this.productId = res.data[0].productid;
              this.lengthId = prrId.length;
              this.isProduct = false;
            }
            this.showProductConfigList();
          }
        });
      } else {
        this.isNavbar = false;
        this.productId = JSON.parse(localStorage.getItem("productId"));
        let params = {
          productid: this.productId,
          quoteid: this.id,
          currency: this.currency,
          pricebookid: this.pricebookid,
        };
        //默认展示第一个产品
        quotationApi.showPricebookItems(params).then((res) => {
          //默认展示第一个产品
          this.productTitle = res.data[0].productname;
          this.productId = res.data[0].productid;
          this.lengthId = prrId.length;
        });
        this.lengthId = prrId.length;
        this.showProductConfigList();
      }
    },
    //展示产品列表
    showProductConfigList() {
      this.optionConstraint();
      this.parentList();
      let params = {
        productid: this.productId,
        currency: this.currency,
        pricebookid: this.pricebookid,
        featurename: this.projectForm.region,
        cpdm: this.projectForm.dm,
        productname: this.projectForm.name,
        quoteid: this.id,
        quotelineid: this.quotelineid || this.currentuotelineid,
      };
      quotationApi.showProductConfigList(params).then((res) => {
        let radioarr = [];
        //设置表头信息
        res.data.tableLabel.forEach((title) => {
          title.prop = title.apiname;
          title.type = title.fieldtype;
          // title.id = title.id;
          title.width = "calc((100% - 290px) / 5)";
          if (title.prop === "quantity") {
            title.editable = true;
          }
          if (title.prop === "cpdm") {
            title.prop = "productcode";
          }
        });
        this.tableAttr = res.data.tableLabel;
        this.productList = res.data.result;
        //设置默认显示数据
        this.quotenumber = res.data.quotenumber;
        this.productList.forEach((item) => {
          //默认展示第一项
          // this.productTitle=item.pricebookitemname
          if (
            item.functionctategory == "" ||
            item.functionctategory == undefined ||
            item.functionctategory == null
          ) {
            this.isProduct = true;
          } else {
            this.isProduct = false;
            this.featuree = item.functionctategory[0].feature;
            this.isactive = item.functionctategory[0].id;
            item.functionctategory.forEach((ite) => {
              if (ite.feature) {
                this.isProduct = false;
                this.tabspan = true;
                ite.feature.checkAll = false;
                ite.feature.forEach((feature) => {
                  feature.featureId = feature.featurename;
                  this.jiaoyanArr.push(feature);
                  if (feature.featurename === "其他产品选项") {
                    this.productGn = false;
                  } else {
                    this.productGn = true;
                  }
                  //只有最大数量有值
                  if (
                    feature.minoptioncount === "0" &&
                    feature.maxoptioncount !== "0"
                  ) {
                    feature.productXz = this.$i18n.t("label.please.ChooseMax", [
                      feature.maxoptioncount,
                    ]);
                  } //只有最小数量有值
                  if (
                    feature.minoptioncount !== "0" &&
                    feature.maxoptioncount === "0"
                  ) {
                    feature.productXz = this.$i18n.t("label.please.ChooseMin", [
                      feature.minoptioncount,
                    ]);
                  } //最大数量和最小数量都有值
                  if (
                    feature.minoptioncount !== "0" &&
                    feature.maxoptioncount !== "0"
                  ) {
                    feature.productXz = this.$i18n.t(
                      "label.please.ChooseMinAndMax",
                      [feature.minoptioncount, feature.maxoptioncount]
                    );
                  } //最大数量和最小数量都没有值
                  if (
                    (feature.minoptioncount === "0" &&
                      feature.maxoptioncount === "0") ||
                    feature.maxoptioncount === null ||
                    feature.minoptioncount === null
                  ) {
                    feature.productXz = "";
                  }
                  feature.option.forEach((option, index) => {
                    option.index = index;
                    this.controlArr.push(option);
                    option.featureId = ite.id;
                    option.id = option.optionid;
                    //产品选项最大数量(MaxOptionCount)和产品选项最小数量(MinOptionCount)如果值均为1时，该功能下的产品选项前按钮均为单选框。
                    if (
                      option.maxoptioncount == "1" &&
                      option.minoptioncount == "1"
                    ) {
                      // this.radio = option.id;
                      radioarr.push(option.id);
                      this.radio = radioarr[0];
                      //单选显示隐藏
                      option.radioCheck = true;
                      option.check = false;
                      //全选显示隐藏
                      feature.ischeckAll = false;
                    } else {
                      //单选显示隐藏
                      option.radioCheck = false;
                      option.check = true;
                      //全选显示隐藏
                      feature.ischeckAll = true;
                    }
                    //如果产品选项数量字段有值，则取该值；如果没有值，则默认数量为1。
                    if (
                      option.quantity === "" ||
                      option.quantity === undefined ||
                      option.quantity === null
                    ) {
                      option.quantity = "1.00";
                    } else {
                      option.quantity = this.cutOutNum(option.quantity, 2);
                    }
                    //产品选项-单价(UnitPrice)【币种（12, 2）】。如果产品选项单价字段有值，则取该值；如果没有值，则取该产品价格手册条目的价目表价格。
                    if (
                      option.unitprice === "" ||
                      option.unitprice === undefined ||
                      option.unitprice === null
                    ) {
                      option.unitprice = this.cutOutNum(option.listprice, 2);
                    } else {
                      option.unitprice = this.cutOutNum(option.unitprice, 2);
                    }
                    if (option.bundled === "true") {
                      option.unitprice = "0.00";
                    }
                    //如果是必须配置的默认置灰并勾选
                    if (option.required === "true") {
                      option.selectedd = true;
                      option.isCheck = true;
                    } else {
                      option.selectedd = false;
                      option.isCheck = false;
                    }
                    if (
                      option.selected === "true" ||
                      option.required === "true"
                    ) {
                      option.isCheck = true;
                    } else {
                      option.isCheck = false;
                    }
                    //如果是编辑报价行跳过来的数量使用编辑报价行带来的数量
                    if (option.savetolinequantity) {
                      option.quantity = this.cutOutNum(
                        option.savetolinequantity,
                        2
                      );
                    }
                    //报价行跳过来数据回显
                    if (
                      this.configEcho == null ||
                      this.configEcho == undefined ||
                      this.configEcho == ""
                    ) {
                      //报价单小扳手跳转必选和包含规则清空
                      if (this.pageTypes === "quote") {
                        if (
                          option.required === "true" ||
                          option.selected === "true"
                        ) {
                          option.isCheck = false;
                        }
                      }
                    } else {
                      //重新配置数据回显
                      if (
                        option.required === "true" ||
                        option.selected === "true"
                      ) {
                        option.isCheck = false;
                      }
                      this.configEcho.forEach((item) => {
                        if (
                          item.productoption === option.id &&
                          option.radioCheck === true
                        ) {
                          this.radio = option.id;
                        }
                        if (item.productoption === option.id) {
                          if (item.quantitysetup) {
                            option.quantity = this.cutOutNum(
                              item.quantitysetup
                            );
                            option.quantitysetup = option.quantity;
                          } else {
                            option.quantity = this.cutOutNum(item.quantity);
                          }
                          option.isCheck = true;
                        }
                        if (item.childproduct) {
                          item.childproduct.forEach((ite) => {
                            this.configChildren.push({
                              id: ite.id,
                              featurename: feature.featurename,
                              parentd: item.product,
                              product: ite.product,
                              productoption: ite.productoption,
                              quantitysetup: ite.quantity,
                            });
                            this.configChildren = this.unique(
                              this.configChildren,
                              "product"
                            );
                            if (ite.childproduct) {
                              ite.childproduct.forEach((it) => {
                                this.configChildrenTwo.push({
                                  id: it.id,
                                  featurename: feature.featurename,
                                  parentd: ite.product,
                                  product: it.product,
                                  productoption: ite.productoption,
                                  quantitysetup: it.quantity,
                                });
                                this.configChildrenTwo = this.unique(
                                  this.configChildrenTwo,
                                  "product"
                                );
                                if (it.childproduct) {
                                  it.childproduct.forEach((itt) => {
                                    this.configChildrenThree.push({
                                      id: itt.id,
                                      featurename: feature.featurename,
                                      parentd: it.product,
                                      product: itt.product,
                                      productoption: itt.productoption,
                                      quantitysetup: itt.quantity,
                                    });
                                  });
                                  this.configChildrenThree = this.unique(
                                    this.configChildrenThree,
                                    "product"
                                  );
                                }
                              });
                            }
                          });
                        }
                      });
                    }
                    //多个产品切换时选中数据回显
                    if (
                      this.newOptionArr == null ||
                      this.newOptionArr == undefined ||
                      this.newOptionArr == ""
                    ) {
                      return;
                    } else {
                      if (
                        option.required === "true" ||
                        option.selected === "true"
                      ) {
                        option.isCheck = false;
                      }
                      this.newOptionArr.forEach((item) => {
                        if (
                          item.productoption === option.optionid &&
                          item.id === this.productId
                        ) {
                          option.isCheck = true;
                        }
                      });
                    }
                    //如果有下级的话展示小扳手
                    if (
                      option.optionconfigurationtype === "" ||
                      option.optionconfigurationtype === undefined ||
                      option.optionconfigurationtype === null ||
                      option.optionconfigurationtype == "无需配置"
                    ) {
                      option.configImgs = false;
                    } else {
                      option.configImgs = true;
                    }
                    ///是依赖关系的话默认把受约束产品置灰
                    if (
                      this.constraintList !== "" ||
                      this.constraintList !== undefined ||
                      this.constraintList !== null
                    ) {
                      this.constraintList.forEach((constType) => {
                        if (constType.type === "依赖") {
                          this.Srely.push({ id: constType.constrainingoption });
                          //如果是受依赖的optionid默认置灰不勾选，只有依赖的optionid为true是可以勾选
                          if (constType.constrainedoption === option.optionid) {
                            option.selectedd = true;
                            option.isCheck = false;
                            if (option.chosenproductoption) {
                              option.isCheck = true;
                            }
                          }
                        }
                      });
                    }
                    //必须配置的子产品
                    if (
                      (option.radioCheck === false &&
                        option.isCheck === true) ||
                      this.radio === option.id
                    ) {
                      this.optionListArr.push({
                        ids: option.id,
                        id: option.productid,
                        featurename: feature.featurename,
                        product: option.optionalsku,
                        productoption: option.optionid,
                        quantitysetup: option.quantity,
                      });
                    }
                  });
                });
              } else {
                this.isProduct = true;
                this.tabspan = false;
              }
            });
          }
        });
      });
    },
    //筛选器开关
    isFilterBtn() {
      if (this.isFilter) {
        this.isFilter = false;
      } else {
        this.isFilter = true;
      }
    },
    //筛选器关闭
    handleClick() {
      this.isFilter = false;
    },
    //左侧选项卡
    jump(index, item) {
      this.verify();
      if (!this.rules) {
        return false;
      } else {
        this.productId = item.id;
        this.productTitle = item.title;
        this.isactivee = index;
        this.loadings = true;
        this.productList = [];
        this.optionListArr = [];
        this.showProductConfigList();
        setTimeout(() => {
          this.loadings = false;
        }, 500);
      }
    },
    cancelConfig() {
      if (this.pageTypes === "quote") {
        this.$parent.pageType = "quote";
      }
      if (this.pageTypes === "chooseProduct") {
        this.$parent.pageType = "chooseProduct";
        //取消后选择页面初始化
        this.$parent.$refs.chooseProducts.clearBtn();
      }
    },
    cancle(
      configQuo,
      configChildren,
      configChildrenTwo,
      configChildrenThree,
      configChildrenfour
    ) {
      this.childQuo = false;
      this.configQuo = configQuo;
      this.configChildren = configChildren;
      this.configChildrenTwo = configChildrenTwo;
      this.configChildrenThree = configChildrenThree;
      this.configChildrenfour = configChildrenfour;
    },
  },
  beforeUpdate() {
    //  this.showProductConfigList();
  },
};
</script>

<style lang="scss" scoped>
.objectTableMain {
  border-left: none;
  border-right: none;
  border-radius: 3px;
  position: relative;
  height: 100%;
  padding: 10px 10px 10px 10px;
  .object_box {
    border-radius: 3px;
    border: 1px solid #dedcda;
    height: 100%;
    background: #fff;
    .price_box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      background: #f5f5f5;
      border: 1px solid #f5f5f5;
      border-radius: 3px 3px 0 0;
      border-radius: 3px 3px 0px 0px;
      .tit {
        line-height: 1.2;
        div {
          width: 150px;
          margin-top: 3px;
          p:first-child {
            font-size: 12px;
          }
          p:nth-child(2) {
            font-size: 16px;
            color: #080707;
            font-weight: 600;
          }
        }
      }
      .search {
        float: right;
        width: 278px;
        margin-right: 10px;
        .btn_group {
          display: flex;
          justify-content: flex-end;
          .shaixuan {
            width: 30px;
            height: 30px;
            border: 1px solid #ccc;
            background: #fff;
            border-radius: 3px;
            cursor: pointer;
            margin-right: 10px;
            svg {
              width: 16px;
              height: 30px;
              margin-left: 6px;
            }
          }
          ::v-deep .el-button--small,
          .el-button--small.is-round {
            padding: 8px 17px !important;
            color: #006dcc;
          }
          ::v-deep .el-button:hover {
            background: #fff;
            border: 1px solid #dedcda;
            color: #006dcc;
          }
        }
        div {
          position: relative;
          img {
            width: 16px;
            position: absolute;
            top: 7px;
            left: 10px;
          }
        }
      }
      div {
        height: 30px;
        .viewImg {
          margin-right: 10px;
          margin-left: 10px;
          width: 37px;
          height: 37px;
          float: left;
        }
        .title {
          width: 400px;
        }
        p:first-child {
          width: 100%;
          margin-top: -2px;
          margin-bottom: 0;
        }
        p:nth-child(2) {
          width: 100%;
          margin: 0;
          font-size: 16px;
        }
      }

      .icon {
        color: #888888;
        position: relative;
        top: -32px;
        margin: 0 12px;
        cursor: pointer;
      }
    }
    .wrap {
      width: 100%;
      height: 91%;
      display: flex;
      .navigationss {
        height: 100%;
      }
      .configTable {
        width: 100%;
        height: 100%;
        overflow: auto;
        background: #fff;
        .quotation-table {
          width: 100%;
          background: #fff;
          // border: 1px solid #dedcda;
          // border-bottom: 1px solid #dedcda;
          border-radius: 3px;
          .goods {
            width: 100%;
            height: 76.9px;
            border-bottom: 1px solid #dddbda;
            margin-bottom: 15.1px;
            .jgsc {
              margin-left: 10px;
              font-family: SourceHanSansCN-Regular;
              font-size: 16px;
              color: #080707;
              letter-spacing: 0;
              line-height: 52px;
            }
            .productTab {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              .configTab {
                width: 100%;
                span {
                  cursor: pointer;
                  font-family: SourceHanSansCN-Bold;
                  font-size: 12px;
                  color: #080707;
                  letter-spacing: 0;
                  line-height: 30px;
                  margin-left: 40px;
                }
                span:nth-child(1) {
                  margin-left: 10px;
                }

                .active {
                  font-family: SourceHanSansCN-Bold;
                  font-size: 12px;
                  color: #006dcc;
                  letter-spacing: 0;
                  line-height: 30px;
                  border-bottom: 2px solid #006dcc;
                }
              }
              // .configTab:nth-child(1) {
              //   margin-right: 40px;
              // }
            }
          }
          .Navtable {
            border: 1px solid #dddbda;
            border-radius: 0 0 3px 3px;
            .el-radio {
              margin: 0;
              padding-left: 10px;
            }
          }
          .twoTitle {
            padding: 10px;
            width: 100%;
          }
          .goodstypee {
            width: 99%;
            height: 41px;
            display: block;
            margin: 10px;
            background: #fafaf9;
            border: 1px solid #c8c6c6;
            border-radius: 3px 3px 0 0;
            font-family: SourceHanSansCN-Regular;
            padding-left: 10px;
            font-size: 16px;
            color: #080707;
            letter-spacing: 0;
            line-height: 41px;
          }
          .goodstype {
            width: 100%;
            height: 74px;
            display: block;
            background: #fafaf9;
            border: 1px solid #c8c6c6;
            border-radius: 3px 3px 0 0;
            font-family: SourceHanSansCN-Regular;
            padding-left: 10px;
            font-size: 16px;
            border-bottom: none;
            color: #080707;
            letter-spacing: 0;
            line-height: 41px;
            p {
              margin-bottom: 0;
            }
            p:nth-child(2) {
              font-family: SourceHanSansCN-Normal;
              font-size: 12px;
              color: #888888;
              letter-spacing: 0;
            }
          }
          ul {
            margin-bottom: 0;
            display: flex;
            background: #fafaf9;
            li {
              padding: 0 10px;
              height: 40px;
              line-height: 40px;
              font-size: 14px;
              color: #3e3e3c;
            }

            .tootip-span {
              max-width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              display: inline-block;
            }

            .checkbox-li {
              width: 40px;
              padding: 0;
              text-align: center;
              border: none;
            }

            .number-li {
              width: 40px;
              padding: 0;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
</style>
